<template>
  <v-container>
    <v-row v-if="totalInventory != null">
      <v-col cols="12">
        <v-form ref="productForm">
          <p class="text-h6 mb-0">{{ productData.name_product }}</p>
          <p class="mb-1">{{ productData.product_id }}</p>
          <p class="caption ma-0 text-center">
            Ingrese la cantidad que desees añadir al inventario actual
          </p>
          <div class="mt-3 mb-2 text-center">
            <p class="ma-0 text-h2">
              {{ inventory ? inventory : 0 }}
            </p>
          </div>
          <div class="d-flex justify-center mb-6">
            <div
              style="width: 170px"
              class="d-flex align-center justify-center"
            >
              <v-btn icon elevation="2" class="mr-2" @click="inventory--">
                <v-icon>remove</v-icon>
              </v-btn>
              <v-text-field
                ref="inventory"
                class="centered-input"
                v-model="inventory"
                hide-details
                dense
                outlined
                :rules="inventoryRules"
                type="number"
              ></v-text-field>
              <v-btn icon elevation="2" class="ml-2" @click="inventory++">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </div>
          <v-card outlined class="px-2 py-1">
            <p class="ma-0 caption text--secondary">
              Total de piezas actuales
            </p>
            <p class="ma-0 text-subtitle-1 pl-1">
              {{ totalInventory | isInventoried }}
            </p>
          </v-card>
          <v-card outlined class="px-2 py-1 mt-1">
            <p class="ma-0 caption text--secondary">
              Inventario minimo (valor de referencia)
            </p>
            <p class="ma-0 text-subtitle-1 pl-1">
              {{ minInventory }}
            </p>
          </v-card>
          <v-card outlined class="px-2 py-1 mt-1">
            <p class="ma-0 caption text--secondary">
              Total de piezas por añadir
            </p>
            <p class="ma-0 text-subtitle-1 pl-1">
              {{ inventory }}
            </p>
          </v-card>
          <v-card outlined class="px-2 py-1 mt-1">
            <p class="ma-0 caption text--secondary">
              Inventario total con piezas añadidas
            </p>
            <p class="ma-0 text-subtitle-1 pl-1">
              {{
                (parseInt(inventory) + parseInt(totalInventory)) | isInventoried
              }}
            </p>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12">
        <v-btn
          block
          color="info"
          @click.prevent="applyChanges"
          :disabled="inventory <= 0"
          >Actualizar inventario</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-else class="text-center my-5">
      Este producto no cuenta con inventario activado
    </v-row>
  </v-container>
</template>

<script>
import { notSpecialCharacters, required } from "@/services/ValidatorService";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      inventory: 0,
      inventoryRules: [required, notSpecialCharacters]
    };
  },

  methods: {
    ...mapActions("products", ["updateProductStock"]),
    applyChanges() {
      const totaMoneyToAddPrice =
        parseInt(this.inventory) * parseFloat(this.productData.price);
      const totaMoneyToAdd =
        parseInt(this.inventory) * parseFloat(this.productData.cost_price);
      if (this.$refs.productForm.validate()) {
        this.updateProductStock({
          product_id: this.productID,
          total_to_add: this.inventory,
          total_money_to_add:
            Math.round((totaMoneyToAdd + Number.EPSILON) * 100) / 100,
          total_money_to_add_price:
            Math.round((totaMoneyToAddPrice + Number.EPSILON) * 100) / 100
        });
        this.$refs.inventory.reset();
        this.inventory = 0;
      }
    }
  },
  filters: {
    isInventoried(inventory) {
      if (inventory == 0) return inventory;
      if (inventory > 1) return inventory + " Piezas";
      else return inventory + " Pieza";
    },
    getQuantityLetter(inventory) {
      if (inventory == 0) return " Piezas";
      if (inventory > 1) return " Piezas";
      else return " Pieza";
    }
  },
  props: {
    productID: {
      required: true
    },
    totalInventory: {
      required: true
    },
    minInventory: {
      required: true
    },
    productData: {
      required: true
    }
  }
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
