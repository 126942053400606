<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Detalle de producto
        </h3>
      </v-col>
      <v-col>
        <v-card width="100%" class="card_background">
          <v-tabs grow :value="tabSection" @change="changeTab">
            <v-tab>Detalle</v-tab>
            <v-tab>Stock</v-tab>
            <v-tab>Edición</v-tab>

            <v-tab-item :key="0" class="card_background">
              <v-slide-x-transition leave-absolute mode="in-out">
                <app-product-detail
                  v-if="!loader"
                  :productID="productID"
                  :productData="productData"
                  :currentBranchID="currentBranchID"
                ></app-product-detail>
              </v-slide-x-transition>
              <v-container v-if="loader">
                <v-skeleton-loader
                  type="card, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, actions"
                ></v-skeleton-loader>
              </v-container>
              <v-container v-if="notFound">
                <div class="text-center mb-4">
                  <v-icon size="80">image_not_supported</v-icon>
                </div>
                <div class="mx-4 mb-4 text-center">
                  El producto que buscas no existe en tu base de datos, o el
                  código (SKU) fue ingresado incorrectamente, intenta nuevamente
                  :(
                </div>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="1" class="card_background">
              <v-slide-x-transition leave-absolute mode="in-out">
                <app-product-stock
                  v-if="!loader"
                  :productID="productID"
                  :productData="productData"
                  :minInventory="
                    transformInventoryData(productData, 'min_inventory')
                  "
                  :totalInventory="
                    transformInventoryData(productData, 'products')
                  "
                ></app-product-stock>
              </v-slide-x-transition>
              <v-container v-if="loader">
                <v-skeleton-loader
                  type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, actions"
                ></v-skeleton-loader>
              </v-container>
              <v-container v-if="notFound">
                <div class="text-center mb-4">
                  <v-icon size="80">image_not_supported</v-icon>
                </div>
                <div class="mx-4 mb-4 text-center">
                  El producto que buscas no existe en tu base de datos, o el
                  código (SKU) fue ingresado incorrectamente, intenta nuevamente
                  :(
                </div>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="2" class="card_background">
              <v-slide-x-transition leave-absolute mode="in-out">
                <div v-if="tabSection === 2">
                  <app-edit-product
                    v-if="!loader"
                    :productID="productID"
                    :productDataFirebase="productData"
                    :totalInventoryData="
                      transformInventoryData(productData, 'products')
                    "
                    :minInventoryData="
                      transformInventoryData(productData, 'min_inventory')
                    "
                  ></app-edit-product>
                </div>
              </v-slide-x-transition>
              <v-container v-if="loader">
                <v-skeleton-loader
                  type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, card"
                ></v-skeleton-loader>
              </v-container>
              <v-container v-if="notFound">
                <div class="text-center mb-4">
                  <v-icon size="80">image_not_supported</v-icon>
                </div>
                <div class="mx-4 mb-4 text-center">
                  El producto que buscas no existe en tu base de datos, o el
                  código (SKU) fue ingresado incorrectamente, intenta nuevamente
                  :(
                </div>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProductDetail from "@/components/products/ProductDetailData";
import EditProduct from "../../components/products/EditProduct";
import ProductStock from "../../components/products/ProductStock";
import { database } from "@/config/firebase";
import { errorMessage, showMessage } from "@/services/MessageService";
export default {
  data() {
    return {
      productData: null,
      productDatabaseRef: null,
      loader: true,
      notFound: false
    };
  },
  beforeDestroy() {
    this.removeListenerDatabase();
    this.changeStateTabItem(0);
  },
  mounted() {
    this.getInventoryData();
    this.gotToSection(this.$route.query);
  },
  methods: {
    ...mapActions("products", ["changeStateTabItem"]),
    async getInventoryData() {
      try {
        this.productDatabaseRef = await database(`store/${this.productID}`);
        this.productDatabaseRef.on("value", async snapshot => {
          if (snapshot.exists()) {
            this.productData = snapshot.val();
            this.loader = false;
          } else {
            this.loader = false;
            this.notFound = true;
            showMessage({
              message: "El producto que intentas buscar ya no esta disponible",
              color: "success",
              timeout: 2500
            });
          }
        });
      } catch (error) {
        this.loader = false;
        errorMessage({
          message:
            "Upss algo salio mal al obtener la información del dashboard. Code:" +
            error
        });
      }
    },
    transformInventoryData(productData, type) {
      if (productData.branch_office) {
        if (productData.branch_office[this.currentBranchID].inventory) {
          const branchOfficeData =
            productData.branch_office[this.currentBranchID].inventory;
          if (type === "products") {
            return branchOfficeData.total_inventory;
          }
          if (type === "min_inventory") {
            return branchOfficeData.min_inventory;
          }
          return null;
        }
        return null;
      }
      return null;
    },
    gotToSection(section) {
      if (section) {
        if (section.section === "editar") this.changeStateTabItem(2);
        if (section.section === "stock") this.changeStateTabItem(1);
      }
    },
    async removeListenerDatabase() {
      await this.productDatabaseRef.off("value");
      this.productDatabaseRef = null;
    },
    changeTab(value) {
      this.changeStateTabItem(value);
    }
  },
  props: {
    productID: {
      required: true
    }
  },
  computed: {
    ...mapState("products", ["tabSection"]),
    ...mapState("business", ["currentBranchID"]),
    ...mapState("user", ["data"])
  },
  components: {
    AppProductDetail: ProductDetail,
    AppEditProduct: EditProduct,
    AppProductStock: ProductStock
  }
};
</script>
<style>
.v-rating .v-icon {
  padding: 0 !important;
}
</style>
