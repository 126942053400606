<template>
  <v-row>
    <v-col>
      <v-container class="pb-0 pt-0" v-if="productData">
        <v-form ref="productForm">
          <div class="text-h6">{{ productData.name_product }}</div>
          <div class="mt-2">
            <v-text-field
              label="Código (SKU)"
              v-model="productData.barcode"
              :rules="fieldRequired"
              disabled
              outlined
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Nombre del producto"
              v-model="productData.name_product"
              :rules="fieldRequired"
              outlined
            ></v-text-field>
          </div>
          <div>
            <v-textarea
              label="Descripción"
              v-model="productData.description"
              outlined
              :rules="fieldRequired"
              rows="3"
              auto-grow
            ></v-textarea>
          </div>
          <v-divider class="mb-2"></v-divider>
          <div class="mb-6">
            <div>Total de inventario</div>
            <div v-if="enableInventory" class="caption">
              Este producto no cuenta con el inventario habilitado, esta
              funcionalidad estará disponible en versiones posteriores.
            </div>
            <div v-if="enableInventory" class="text-center">
              <v-btn dense @click="enableInventoryAction">Habilitar</v-btn>
            </div>
            <div class="d-flex justify-center text-center">
              <v-slide-x-transition leave-absolute mode="in-out">
                <div v-if="totalInventoryData | !enableInventory">
                  <p class="caption mb-0">Inventario Actual</p>
                  <div
                    style="width: 200px"
                    class="d-flex align-center justify-center mt-2"
                  >
                    <v-btn
                      icon
                      elevation="2"
                      class="mr-2"
                      @click="inventoryData--"
                    >
                      <v-icon>remove</v-icon>
                    </v-btn>
                    <v-text-field
                      class="centered-input"
                      v-model="inventoryData"
                      hide-details
                      outlined
                      :rules="fieldRequiredWithNSC"
                      type="number"
                    ></v-text-field>
                    <v-btn
                      icon
                      elevation="2"
                      class="ml-2"
                      @click="inventoryData++"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </div>
                  <p class="caption mb-0 mt-2">Cantidad minima de productos</p>
                  <div
                    style="width: 200px"
                    class="d-flex align-center justify-center mt-2"
                  >
                    <v-btn
                      icon
                      elevation="2"
                      class="mr-2"
                      @click="minInventory--"
                    >
                      <v-icon>remove</v-icon>
                    </v-btn>
                    <v-text-field
                      class="centered-input"
                      v-model="minInventory"
                      hide-details
                      outlined
                      :rules="fieldRequiredWithNSC"
                      type="number"
                    ></v-text-field>
                    <v-btn
                      icon
                      elevation="2"
                      class="ml-2"
                      @click="minInventory++"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-slide-x-transition>
            </div>
          </div>
          <v-divider class="mb-4"></v-divider>
          <div>
            <v-text-field
              label="Precio venta"
              type="number"
              prepend-inner-icon="attach_money"
              :rules="fieldRequired"
              v-model="productData.price"
              outlined
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Precio costo"
              type="number"
              :rules="fieldRequired"
              prepend-inner-icon="attach_money"
              v-model="productData.cost_price"
              outlined
            ></v-text-field>
          </div>
          <v-divider class="mb-2"></v-divider>
          <div class="mb-2 d-flex justify-space-between">
            <span>Precio por mayoreo</span>
            <v-btn color="primary" icon @click="addWhosale"
              ><v-icon>add_circle</v-icon></v-btn
            >
          </div>
          <div v-if="!wholesaleData" class="caption mb-4">
            Da clic en el botón de más para agregar nuevos precios por mayoreo
          </div>
          <template v-for="(wholesale, index) in wholesaleData">
            <div class="d-flex" :key="index + '-whosale'">
              <v-text-field
                ref="minimumProducts"
                v-model="wholesale.minimum_products"
                :rules="fieldRequiredWithNSC"
                outlined
                label="Cantidad minima"
                type="number"
                class="mr-1"
              ></v-text-field>
              <v-text-field
                v-model="wholesale.wholesale_price"
                :rules="fieldRequiredWithNSC"
                outlined
                label="Precio mayoreo"
                type="number"
                class="ml-1"
              ></v-text-field>
              <v-btn
                icon
                elevation="2"
                class="mt-3 ml-1"
                @click="removeWhosale(index)"
                ><v-icon>close</v-icon></v-btn
              >
            </div>
          </template>
          <v-divider class="mb-2"></v-divider>
          <div>
            <p class="mb-1">Foto del articulo</p>
            <div v-if="!productData.photoURL" class="caption mb-1">
              Agrega imágenes a este producto dando clic en el siguiente
              recuadro
            </div>
            <v-img
              v-if="productData.photoURL"
              :src="productData.photoURL"
              height="190"
              contain
              class="mb-2"
            ></v-img>
            <v-card
              v-if="newImageBottom"
              @click="uploadImage"
              elevation="0"
              class="mb-2"
            >
              <app-upload-images ref="uploadImages"></app-upload-images>
            </v-card>
            <v-btn block @click="newImageBottom = true" v-if="!newImageBottom"
              >Agregar nueva imagen</v-btn
            >
          </div>
        </v-form>
        <v-divider class="my-3"></v-divider>
        <div class="d-flex justify-space-between">
          <v-btn icon color="error" @click="dialogDelete = true"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-btn color="info mb-2" @click="applyChanges">Guardar cambios</v-btn>
        </div>
      </v-container>
    </v-col>
    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="text-subtitle-2">
          Estas seguro de eliminar el producto {{ productID }}?
        </v-card-title>
        <v-card-text class="caption"
          >Ya no podrás recuperar la información este artículo y por lo
          consiguiente no estará disponible esta información, además ten en
          cuenta que este producto se comparte para todas las sucursales, por lo
          que se verán afectadas las demás</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialogDelete = false">
            Cancelar
          </v-btn>
          <v-btn
            color="error"
            text
            @click="
              deleteProductDialog();
              dialogDelete = false;
            "
          >
            continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { notSpecialCharacters, required } from "@/services/ValidatorService";
import UploadImages from "@/components/app/UploadImages";
import moment from "moment";

export default {
  data() {
    return {
      dialogDelete: false,
      newImageBottom: false,
      productData: {
        ...this.productDataFirebase
      },
      enableInventory: this.totalInventoryData != null ? false : true,
      inventoryData: this.totalInventoryData ? this.totalInventoryData : 0,
      minInventory: this.minInventoryData ? this.minInventoryData : 0,
      wholesaleData: this.productDataFirebase.wholesale,
      fieldRequired: [required],
      fieldRequiredWithNSC: [required, notSpecialCharacters]
    };
  },
  computed: {
    ...mapState("products", ["tabSection"]),
    ...mapState("app", ["imageData", "loadImage"]),
    ...mapState("business", ["currentBranchID", "branchOffice"])
  },
  beforeDestroy() {
    this.resetImageData();
  },
  methods: {
    ...mapActions("business", ["getBranchOfficeData"]),
    ...mapActions("products", ["editProduct", "removeProduct"]),
    ...mapActions("app", ["resetImageData"]),
    applyChanges() {
      let totalInventory = null;
      let totalMoney = null;
      let totalMoneyPrice = null;
      if (this.$refs.productForm.validate()) {
        if (
          !this.enableInventory |
          this.productData.branch_office[this.currentBranchID].inventory
        ) {
          this.setNewInventory(this.inventoryData, this.minInventory);
          totalInventory = this.inventoryData - this.totalInventoryData;
          totalMoney =
            parseInt(totalInventory) * parseFloat(this.productData.cost_price);
          totalMoneyPrice =
            parseInt(totalInventory) * parseFloat(this.productData.price);
        }
        const data = {
          productData: this.productData
        };
        if (this.imageData.blob) {
          data.photoData = this.imageData.blob;
        }
        if (totalInventory != null) {
          if (totalInventory != 0) {
            data.productTotalVariance = totalInventory;
            data.productTotalMoney =
              Math.round((totalMoney + Number.EPSILON) * 100) / 100;
            data.productTotalMoneyPrice =
              Math.round((totalMoneyPrice + Number.EPSILON) * 100) / 100;
          }
        }
        this.editProduct(data);
        this.minInventory = this.minInventoryData ? this.minInventoryData : 0;
        this.inventoryData = this.totalInventoryData
          ? this.totalInventoryData
          : 0;
      }
    },
    deleteProductDialog() {
      let totalProducts = 0;
      let totalMoney = 0;
      let totalMoneyPrice = 0;
      for (const branchID in this.productDataFirebase.branch_office) {
        if (
          Object.hasOwnProperty.call(
            this.productDataFirebase.branch_office,
            branchID
          )
        ) {
          const inventoryData = this.productDataFirebase.branch_office[
            branchID
          ];
          if (inventoryData.inventory) {
            totalProducts += inventoryData.inventory.total_inventory;
            totalMoney =
              parseInt(totalProducts) *
              parseFloat(this.productDataFirebase.cost_price);
            totalMoneyPrice =
              parseInt(totalProducts) *
              parseFloat(this.productDataFirebase.price);
          }
        }
      }
      this.removeProduct({
        product_id: this.productData.product_id,
        photo: this.productData.photoURL ? true : false,
        totalProducts,
        totalMoney: Math.round((totalMoney + Number.EPSILON) * 100) / 100,
        totalMoneyPrice:
          Math.round((totalMoneyPrice + Number.EPSILON) * 100) / 100
      });
    },
    setNewInventory(inventoryData, minInventory) {
      const branchOfficeID = this.currentBranchID;
      if (this.productData.branch_office[branchOfficeID].inventory) {
        this.productData.branch_office[
          branchOfficeID
        ].inventory.min_inventory = parseInt(minInventory);
        this.productData.branch_office[
          branchOfficeID
        ].inventory.total_inventory = parseInt(inventoryData);
        this.productData.branch_office[
          branchOfficeID
        ].metadata.last_updated = moment().unix();
      }
    },
    removeWhosale(index) {
      this.productData.wholesale.splice(index, 1);
      this.wholesaleData.splice(index, 1);
    },
    addWhosale() {
      if (this.productData.wholesale) {
        this.productData.wholesale = this.wholesaleData;
        this.wholesaleData.push({
          minimum_products: null,
          wholesale_price: null
        });
      } else {
        this.wholesaleData = [];
        this.wholesaleData.push({
          minimum_products: null,
          wholesale_price: null
        });
        this.productData.wholesale = this.wholesaleData;
      }
    },
    uploadImage() {
      this.$refs.uploadImages.$refs.upload.click();
    },
    async enableInventoryAction() {
      await this.getBranchOfficeData();
      this.enableInventory = false;
      this.branchOffice.forEach(branch => {
        if (branch.id === this.currentBranchID) {
          this.productData.branch_office[branch.id] = {
            inventory: {
              total_inventory: parseInt(this.inventoryData),
              min_inventory: parseInt(this.minInventory ? this.minInventory : 0)
            },
            metadata: {
              last_updated: moment().unix()
            }
          };
        } else {
          this.productData.branch_office[branch.id] = {
            inventory: {
              total_inventory: 0,
              min_inventory: parseInt(this.minInventory ? this.minInventory : 0)
            },
            metadata: {
              last_updated: moment().unix()
            }
          };
        }
      });
    }
  },
  props: {
    productID: {
      required: true
    },
    productDataFirebase: {
      required: true
    },
    totalInventoryData: {
      required: true
    },
    minInventoryData: {
      required: true
    }
  },
  components: {
    AppUploadImages: UploadImages
  }
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
